<template>
  <main class="split-container">
    <div class="section split-container__left">
      <h2>Sign In</h2>
      <hr class="ruler--white" />
      <p>Sign in to access your organizations.</p>
    </div>

    <div class="section split-container__right">
      <div class="breadcrumbs overflow-container">
        <router-link class="breadcrumbs__item" to="/">
          Home
        </router-link>
        <span class="breadcrumbs__slash">
          /
        </span>
        <span class="breadcrumbs__item">
          Sign In
        </span>
      </div>

      <router-view />
    </div>
  </main>
</template>
